<div class="order-page">
  <div class="page-content">
    <h1 class="page-title">{{title}}</h1>
    <div class="content" *ngIf="ordersExists">
      <div class="line">
        <label>
          Введите название компании
          <input type="text" value="ООО «КУБ»" disabled>
        </label>
      </div>
      <div class="line">
        <label>
          ИНН
          <input type="text" value="7811346098" disabled>
        </label>
        <label>
          КПП
          <input type="text" value="781101001" disabled>
        </label>
      </div>
      <div class="line">
        <label>
          Расчетный счет
          <input type="text" value="40702810800021229445" disabled>
        </label>
        <label>
          Корреспондентский счет
          <input type="text" value="30101810800000000858" disabled>
        </label>
      </div>
      <div class="line">
        <label>
          Банк и отделение
          <input type="text" value="ПАО «СБЕРБАНК»" disabled>
        </label>
      </div>
      <div class="line">
        <label class="small">
          БИК
          <input type="text" value="044525225" disabled>
        </label>
        <label class="big">
          ОГРН
          <input type="text" value="5213694569853" disabled>
        </label>
      </div>
      <div class="line">
        <label>
          Генеральнй директор
          <input type="text" value="Евгений Александрович Иванов" disabled>
        </label>
      </div>
      <div class="line">
        <label>
          Контактное лицо
          <input type="text" value="Александра Андреевна Васильева" disabled>
        </label>
      </div>
      <div class="line">
        <label>
          Телефон
          <input type="text" value="8 812 633 3340" disabled>
        </label>
        <label>
          E-mail
          <input type="text" value="office@kubinfo.ru" disabled>
        </label>
      </div>
    </div>
    <div class="content" *ngIf="!ordersExists">
      В Вашей корзине нет сервисов для оформления заказа
    </div>
  </div>
  <app-short-order></app-short-order>
</div>
