/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./payment.component";
var styles_PaymentComponent = [i0.styles];
var RenderType_PaymentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentComponent, data: {} });
export { RenderType_PaymentComponent as RenderType_PaymentComponent };
export function View_PaymentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "payment-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0421\u043F\u0430\u0441\u0438\u0431\u043E \u0437\u0430 \u0412\u0430\u0448 \u0437\u0430\u043A\u0430\u0437. \u0421\u0447\u0435\u0442 \u0431\u0443\u0434\u0435\u0442 \u0441\u0444\u043E\u0440\u043C\u0438\u0440\u043E\u0432\u0430\u043D \u0438 \u043D\u0430\u043F\u0440\u0430\u0432\u043B\u0435\u043D \u043D\u0430 \u0443\u043A\u0430\u0437\u0430\u043D\u043D\u044B\u0439 \u0412\u0430\u043C\u0438 \u0430\u0434\u0440\u0435\u0441 \u044D\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u043E\u0439 \u043F\u043E\u0447\u0442\u044B"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵted(-1, null, ["\u0412 \u043D\u0430\u0447\u0430\u043B\u043E"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 6, 0, "/"); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_PaymentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment", [], null, null, null, View_PaymentComponent_0, RenderType_PaymentComponent)), i1.ɵdid(1, 114688, null, 0, i3.PaymentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentComponentNgFactory = i1.ɵccf("app-payment", i3.PaymentComponent, View_PaymentComponent_Host_0, {}, {}, []);
export { PaymentComponentNgFactory as PaymentComponentNgFactory };
