/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./short-order.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./short-order.component";
import * as i5 from "../../_services/cart.service";
var styles_ShortOrderComponent = [i0.styles];
var RenderType_ShortOrderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShortOrderComponent, data: {} });
export { RenderType_ShortOrderComponent as RenderType_ShortOrderComponent };
function View_ShortOrderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "order-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "rubble"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u20BD"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.price.toLocaleString(); _ck(_v, 4, 0, currVal_1); }); }
export function View_ShortOrderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "short-order"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0421\u043E\u0441\u0442\u0430\u0432 \u0437\u0430\u043A\u0430\u0437\u0430:"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " \u0441\u0435\u0440\u0432\u0438\u0441", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "order-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShortOrderComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 9, "div", [["class", "content-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0418\u0442\u043E\u0433\u043E\u0432\u0430\u044F \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C:"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", " \u20BD"])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(16, 1), (_l()(), i1.ɵted(-1, null, ["\u0412\u044B\u0441\u0442\u0430\u0432\u0438\u0442\u044C \u0441\u0447\u0435\u0442"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.orders; _ck(_v, 7, 0, currVal_2); var currVal_4 = _ck(_v, 16, 0, "/payment"); _ck(_v, 15, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.orders.length; var currVal_1 = ((_co.orders.length === 1) ? "" : ((((_co.orders.length === 2) || (_co.orders.length === 3)) || (_co.orders.length === 4)) ? "\u0430" : "\u043E\u0432")); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = _co.getPrice().toLocaleString(); _ck(_v, 13, 0, currVal_3); }); }
export function View_ShortOrderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-short-order", [], null, null, null, View_ShortOrderComponent_0, RenderType_ShortOrderComponent)), i1.ɵdid(1, 114688, null, 0, i4.ShortOrderComponent, [i5.CartService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShortOrderComponentNgFactory = i1.ɵccf("app-short-order", i4.ShortOrderComponent, View_ShortOrderComponent_Host_0, {}, {}, []);
export { ShortOrderComponentNgFactory as ShortOrderComponentNgFactory };
