<h2>Витрина сервисов</h2>
<div class="search-results">
  <div class="products-list">
    <div class="product-item" *ngFor="let product of searchResults$ | sortBy:'status'" [ngClass]="product.status">
      <div class="product-item--info">

        <div class="info">
          <div class="title">{{product.name}}</div>
          <div class="description">{{product.description}}</div>
        </div>

      </div>
      <div class="cart">
        <div class="product-image">
          <img *ngIf="product.status !== 'locked'" src="/assets/{{product.img}}">
          <svg *ngIf="product.status === 'locked'" style="position: absolute; align-self: center;" width="48" height="57" viewBox="0 0 48 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44 23.9131H4C3.44772 23.9131 3 24.3608 3 24.9131V53.0435C3 53.5958 3.44772 54.0435 4 54.0435H44C44.5523 54.0435 45 53.5958 45 53.0435V24.9131C45 24.3608 44.5523 23.9131 44 23.9131Z" fill="#EDEDED" stroke="#9D9D9D" stroke-opacity="1" stroke-width="5" stroke-miterlimit="10"/>
            <path d="M10.3044 21.1739V12.0915C10.3044 6.54119 16.4674 2 24 2C31.5326 2 37.6957 6.54119 37.6957 12.0915V21.1739" stroke="#9D9D9D" stroke-opacity="1" stroke-width="4" stroke-miterlimit="10"/>
            <path d="M26.7391 36.9696C26.7391 35.2622 25.5522 33.9565 24 33.9565C22.4478 33.9565 21.2609 35.2622 21.2609 36.9696C21.2609 37.8735 21.6261 38.5765 22.1739 39.1792L21.2609 44H26.7391L25.8261 39.1792C26.3739 38.5765 26.7391 37.8735 26.7391 36.9696Z" stroke="#9D9D9D" stroke-opacity="1" stroke-width="2" stroke-miterlimit="10"/>
          </svg>

        </div>
        <!-- <div class="price">{{product.price.toLocaleString()}} <span class="rubble">₽</span></div> -->
        <button class="invert" [routerLink]="(product.status !== 'locked') ? ['/product', product.id] : null">подробнее</button>
        <button class="invert" [routerLink]="(product.status !== 'locked') ? ['/constructor', product.id] : null">настроить</button>
      </div>
    </div>
  </div>
</div>
