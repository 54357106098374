import { Injector, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'modules'
})
export class ModulesPipe implements PipeTransform {

  /* public constructor(private injector: Injector) {
  }*/

  /* transform(value: any, pipeToken: any, pipeArgs: any[]): any {
    if (!pipeToken) {
      return value;
    } else {
      let pipe = this.injector.get(pipeToken);
      return pipe.transform(value, ...pipeArgs);
    }
  } */

  transform(value: any, args?: any): any {
    console.log(value);
    console.log(args);
    return value.filter(item => {
      return item.modules.some((module) => {
        console.log(module, args[module]);
        return args[module];
      });
    });

  }

}
