export class FilterObject {
  analyticsType: {
    classic: false,
    predictive: false,
    prescriptive: false
  };
  transferType: {
    package: false,
    stream: false
  };
  dataType: {
    asIs: false,
    normalised: false,
    enriched: false,
    processed: false
  };
  paymentType: {
    monthly: false,
    perService: false,
    partly: false
  };
}
