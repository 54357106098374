import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CartState } from '@/_models';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor() {}

  private cartSubject = new Subject<CartState>();

  Products = [];

  CartState = this.cartSubject.asObservable();

  addProduct(product: any) {
    console.log('in service');
    this.Products.push(product);
    this.cartSubject.next({loaded: true, products:  this.Products} as CartState);
  }

  removeProduct(id: number) {
    this.Products = this.Products.filter((item) =>  item.id !== id );
    this.cartSubject.next({loaded: false , products:  this.Products} as CartState);
  }

  getNumberOfProducts() {
    return this.Products.length;
  }

  getCart() {
    return this.Products;
  }
  inCart(id) {
    return this.Products.some(product => product.id === id);
  }

}
