import { Component, OnInit } from '@angular/core';
import { CartService } from '@/_services';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.less']
})
export class OrderComponent implements OnInit {

  title = 'Оформление заказа';
  ordersExists = false;

  constructor(
    private cartService: CartService
  ) {

    if (cartService.getCart().length > 0) {
      this.ordersExists = true;
    }

  }

  ngOnInit() {
  }

}
