import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SearchFilter, CartState, FilteredState, Filter, FilterObject } from '@/_models';
import { _ } from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  products = [
    {id: 1, img: 'road-service-img.svg', name: 'Информация о состоянии дорожной инфраструктуры',
      description: 'Информация о состоянии дорожной инфраструктуры на основании анализа данных, полученной из различных источников',
      items: ['', '', ''],
      department: [2, 3],
      region: [1, 2],

      analyticsType: ['classic', 'prescriptive'],
      transferType: ['package', 'stream'],
      dataType: ['enriched', 'processed'],
      paymentType: ['monthly', 'partly'],
        status: 'active',
      modules: [],
      payments: ['service'] , price: 54990,
      demo0: {
        chart0: {
          title: '',
          graph: 'road-service-graph-0_new.svg'
        }
      },
      demo_log: {
        chart1: {
          title: 'Объем данных',
          graph: 'Data_road_service.svg'
        },
        chart2: {
          title: 'Поступление данных',
          graph: 'Incoming_data_road_service.svg'
        }
      },
      demo_tech: {
        chart3: {
          title: 'Нагрузка CPU (ГГц)',
          graph: 'CPU_road_service.svg'
        },
        chart4: {
          title: 'Задействованные ядра CPU',
          graph: 'CPU_cores_road_service.svg'
        },
        chart5: {
          title: 'Память (Гб)',
          graph: 'Memory_road_service.svg'
        },
        chart6: {
          title: 'Диск',
          graph: 'Disk_road_service.svg'
        },
        chart7: {
          title: 'Диск - операции и время отклика',
          graph: 'disk_response_time_road_service.svg'
        }
      }
    },
    {id: 2, img: 'insurance-service-img.svg', name: 'Страхование по запросу',
      description: 'Страхование автомобиля на конкретную поездку с учетом оценки опасности маршрута, погодных условий, дорожной обстановки и других данных. Реализация концепции "страховая биржа',
      items: ['', '', ''],
      department: [1, 2],
      region: [1, 2, 3],

      analyticsType: ['classic', 'prescriptive'],
      transferType: ['package'],
      dataType: ['processed'],
      paymentType: ['monthly'],
 status: 'locked',
      modules: [],
      payments: ['month'], price: 44990,
      },
    {id: 3, img: 'history-service-img.svg', name: 'История автомобиля',
      description: 'Цифровая история автомобиля на основе объективных данных, полученных из различных источников',
      items: ['', '', ''],
      department: [3],
      region: [1, 2, 3],

      analyticsType: ['classic'],
      transferType: ['package'],
      dataType: ['asIs', 'normalised', 'enriched', 'processed'],
      paymentType: ['monthly', 'perService'],
 status: 'active',
      modules: [],
      payments: ['month'], price: 34990,
      demo0: {
        chart0: {
          title: '',
          graph: 'car-history-graph-0_new.svg'
        }
      },
      demo_log: {
        chart1: {
          title: 'Объем данных',
          graph: 'Data_car_history.svg'
        },
        chart2: {
          title: 'Поступление данных',
          graph: 'Incoming_data_car_history.svg'
        }
      },
      demo_tech: {
        chart3: {
          title: 'Нагрузка CPU (ГГц)',
          graph: 'CPU_car_history.svg'
        },
        chart4: {
          title: 'Задействованные ядра CPU',
          graph: 'CPU_cores_car_history.svg'
        },
        chart5: {
          title: 'Память (Гб)',
          graph: 'Memory_car_history.svg'
        },
        chart6: {
          title: 'Диск',
          graph: 'Disk_car_history.svg'
        },
        chart7: {
          title: 'Диск - операции и время отклика',
          graph: 'disk_response_time_car_history.svg'
        }
      }

    },
    {id: 4, img: 'repair-service-img.svg', name: 'Предиктивная (прогнозная) диагностика ТС',
      description: 'Предиктивная (прогнозная) диагностика ТС на основе аналитики данных, полученных от подключенных ТС и других источников информации',
      items: ['', '', ''],
      department: [2],
      region: [1, 2],

      analyticsType: ['classic', 'predictive'],
      transferType: ['stream'],
      dataType: ['asIs', 'normalised'],
      paymentType: ['monthly', 'partly'],
 status: 'active',
      modules: [],
      payments: ['service'], price: 24990,
      demo0: {
        chart0: {
          title: '',
          graph: 'car-service-graph-0_new.svg'
        }
      },
      demo_log: {
        chart1: {
          title: 'Объем данных',
          graph: 'Data_car_service.svg'
        },
        chart2: {
          title: 'Поступление данных',
          graph: 'Incoming_data_car_service.svg'
        }
      },
      demo_tech: {
        chart3: {
          title: 'Нагрузка CPU (ГГц)',
          graph: 'CPU_car_service.svg'
        },
        chart4: {
          title: 'Задействованные ядра CPU',
          graph: 'CPU_cores_car_service.svg'
        },
        chart5: {
          title: 'Память (Гб)',
          graph: 'Memory_car_service.svg'
        },
        chart6: {
          title: 'Диск',
          graph: 'Disk_car_service.svg'
        },
        chart7: {
          title: 'Диск - операции и время отклика',
          graph: 'disk_response_time_car_service.svg'
        }
      }
    },
    {id: 5, img: 'sharing-service-img.svg', name: 'Совместное использование автомобиля (car sharing), версия 2.0',
      description: 'Новая версия сервиса совместного использования автомобиля (car sharing) - обеспечивается гарантия добросовестности пользователей на основе анализа информации из разнородных источников',
      items: ['', '', ''],
      department: [1, 3],
      region: [1],

      analyticsType: ['classic'],
      transferType: ['stream'],
      dataType: ['asIs', 'normalised'],
      paymentType: ['monthly', 'perService'],
 status: 'locked',
      modules: [],
      payments: ['part'], price: 14990},
    {id: 6, img: '', name: 'Загрязненность окружающей среды',
      description: 'Аналитические отчеты о загрязненности окружающей среды выбросами автомобилей в разрезе регионов/районов и временных периодов.',
      items: ['', '', ''],
      department: [4],
      region: [1, 3],

      analyticsType: ['classic', 'predictive'],
      transferType: ['package'],
      dataType: ['asIs', 'normalised'],
      paymentType: ['monthly'],
 status: 'locked',
      modules: [],
      payments: ['part'], price: 24990},
      {id: 7, img: '', name: 'Оценка качества работы общественного автомобильного  транспорта',
      description: 'Сервис уведомления о невыполнении заданных KPI работы общественного транспорта',
      items: ['', '', ''],
      department: [2,3],
      region: [1, 2],

      analyticsType: ['classic'],
      transferType: ['package'],
      dataType: ['asIs', 'normalised', 'enriched'],
      paymentType: ['monthly', 'perService'],
 status: 'locked',
      modules: [],
      payments: ['part'], price: 19990},
  ];

  private searchQuerySubject = new Subject<SearchFilter>();
  private searchSubject = new Subject<CartState>();
  private filterSubject = new Subject<FilterObject>();

  searchResult = this.searchSubject.asObservable();
  searchQuery = this.searchQuerySubject.asObservable();
  filterObject = this.filterSubject.asObservable();

  filters = new Filter();
  filteredProducts = [];

  searchRequest = {
    title: '',
    department: undefined,
    region: undefined
  };

  constructor() {
    this.filteredProducts = this.products;
    this.searchQuerySubject.next({query: { title: '', department: undefined, region: undefined }});
    this.filterSubject.next({
      analyticsType: { classic: false, predictive: false, prescriptive: false },
      transferType: { package: false, stream: false },
      dataType: { asIs: false, normalised: false, enriched: false, processed: false },
      paymentType: { monthly: false, perService: false, partly: false }
    });
    this.searchQuery.subscribe((filters) => {
      this.filteredProducts = this.products;
      if (filters.query.title !== '') { this.filteredProducts = this.filteredProducts.filter(item => {
        return item.name.indexOf(filters.query.title) > -1;
      }); }
      if (filters.query.department !== undefined) {
        this.filteredProducts = this.filteredProducts.filter(item => item.department.indexOf(filters.query.department) > -1);
      }

      if (filters.query.region !== undefined) {
        this.filteredProducts = this.filteredProducts.filter(item => item.region.indexOf(filters.query.region) > -1);
      }

      this.filterSubject.next({
        analyticsType: { classic: false, predictive: false, prescriptive: false },
        transferType: { package: false, stream: false },
        dataType: { asIs: false, normalised: false, enriched: false, processed: false },
        paymentType: { monthly: false, perService: false, partly: false }
      });
      this.searchSubject.next({loaded: true , products:  this.filteredProducts} as FilteredState);
      this.searchRequest = filters.query;
    });
  }

  setParams(query) {
    this.searchQuerySubject.next(query);
  }

  getResults() {
    return this.filteredProducts;
  }

  getSearchFilter() {
    return this.searchRequest;
  }

  toggleFilters(filters) {
    this.filterSubject.next(filters);
    // this.filterObject = filters;
    this.filters = this.createFilterObject(filters);
    let result = this.filteredProducts;
    result = result.filter(item => {
      return  this.filters.analyticsType.length === 0 || _.intersection(item.analyticsType, this.filters.analyticsType).length > 0;
    });
    result = result.filter(item => {
      return  this.filters.transferType.length === 0 || _.intersection(item.transferType, this.filters.transferType).length > 0;
    });
    result = result.filter(item => {
      return  this.filters.dataType.length === 0 || _.intersection(item.dataType, this.filters.dataType).length > 0;
    });
    result = result.filter(item => {
      return  this.filters.paymentType.length === 0 || _.intersection(item.paymentType, this.filters.paymentType).length > 0;
    });
    this.searchSubject.next({loaded: true , products:  result} as FilteredState);
  }

  createFilterObject(filters) {
    const filter = new Filter();
    for (const key in filters) {
      const arr = [];
      for (const key2 in filters[key]) {
        if (filters[key][key2]) {
          arr.push(key2);
        }
      }
      filter[key] = arr;
    }
    return filter;
  }

  getById(id) {
    id = parseInt(id);
    return this.products.filter(product => product.id === id)[0];
  }

  setNewPrice(id, price) {
    this.products.forEach(product => {
      if (product.id === parseInt(id)) {
        product.price = price;
      }
    });
  }

}
