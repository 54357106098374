import { Component, OnInit } from '@angular/core';
import {CartService} from '@/_services';

@Component({
  selector: 'app-short-order',
  templateUrl: './short-order.component.html',
  styleUrls: ['./short-order.component.less']
})
export class ShortOrderComponent implements OnInit {

  orders = [];

  constructor(
    private cartService: CartService
  ) { }

  ngOnInit() {
    this.orders = this.cartService.getCart();
  }

  getPrice() {
    return this.orders.reduce((total, product) => total + product.price, 0);
  }

}
