import * as i0 from "@angular/core";
var ConfigService = /** @class */ (function () {
    function ConfigService() {
        this.configs = [
            {
                id: 4,
                selection: {
                    title: '5. Выбор агрегатов',
                    img: 'track.svg',
                    button: true
                },
                diagnostic: [
                    { name: 'Информирование о состоянии', value: false },
                    { name: 'Упреждающее обслуживание', value: false },
                    { name: 'Предупреждение об аномалиях', value: false },
                    { name: 'Прогноз срока обслуживания', value: false }
                ],
                category: [
                    { name: 'Навигационная информация о ТС', value: true, dataList: [
                            { name: 'Телематические платформы автопроизводителей', value: true, params: [
                                    { name: 'Kamaz', value: true },
                                    { name: 'Toyota', value: true },
                                    { name: 'Nissan', value: true },
                                    { name: 'Hyundai', value: true },
                                    { name: 'Audi', value: true },
                                    { name: 'ГАЗ', value: true },
                                ] },
                            { name: 'Системы мониторинга транспорта', value: false, params: [
                                    { name: 'Gurtam', value: false },
                                    { name: 'Техноком', value: false },
                                    { name: 'Omnicomm', value: false },
                                    { name: 'Форт Телеком', value: false },
                                ] },
                            { name: 'Сервис-провайдеры страховой телематики', value: true, params: [
                                    { name: 'T-One Group', value: false },
                                    { name: 'Р-Телематика', value: false },
                                    { name: 'Meta System', value: false },
                                ] },
                            { name: 'Охранно-поисковые системы', value: false, params: [
                                    { name: 'Starline', value: false },
                                    { name: 'Мега-Ф', value: false },
                                    { name: 'Пандора', value: false },
                                ] },
                            { name: 'Операторы сервисов каршеринга', value: false, params: [
                                    { name: 'Anytime', value: false },
                                    { name: 'BelkaCar', value: false },
                                    { name: 'YouDrive', value: false },
                                    { name: 'Яндекс.Drive', value: false },
                                    { name: 'Car5', value: false },
                                    { name: 'Делимобиль', value: false },
                                    { name: 'Rentmee', value: false },
                                    { name: 'Lifcar', value: false },
                                    { name: 'TimCar', value: false },
                                ] }
                        ] },
                    { name: 'Информация о техническом состоянии ТС', value: false, dataList: [
                            { name: 'Телематические платформы автопроизводителей', value: true, params: [
                                    { name: 'Kamaz', value: true },
                                    { name: 'Toyota', value: true },
                                    { name: 'Nissan', value: true },
                                    { name: 'Hyundai', value: true },
                                    { name: 'Audi', value: true },
                                    { name: 'ГАЗ', value: true },
                                ] },
                            { name: 'Системы мониторинга транспорта', value: false, params: [
                                    { name: 'Gurtam', value: false },
                                    { name: 'Техноком', value: false },
                                    { name: 'Omnicomm', value: false },
                                    { name: 'Форт Телеком', value: false },
                                ] },
                            { name: 'Сервис-провайдеры страховой телематики', value: true, params: [
                                    { name: 'T-One Group', value: false },
                                    { name: 'Р-Телематика', value: false },
                                    { name: 'Meta System', value: false },
                                ] },
                            { name: 'Охранно-поисковые системы', value: false, params: [
                                    { name: 'Starline', value: false },
                                    { name: 'Мега-Ф', value: false },
                                    { name: 'Пандора', value: false },
                                ] },
                            { name: 'Операторы сервисов каршеринга', value: false, params: [
                                    { name: 'Anytime', value: false },
                                    { name: 'BelkaCar', value: false },
                                    { name: 'YouDrive', value: false },
                                    { name: 'Яндекс.Drive', value: false },
                                    { name: 'Car5', value: false },
                                    { name: 'Делимобиль', value: false },
                                    { name: 'Rentmee', value: false },
                                    { name: 'Lifcar', value: false },
                                    { name: 'TimCar', value: false },
                                ] }
                        ] },
                    { name: 'Информация об истории обслуживания ТС', value: false, dataList: [
                            { name: 'Операторы технической помощи', value: true, params: [
                                    { name: 'Российское Автомобильное Товарищество', value: true },
                                    { name: 'Российский АвтоМотоКлуб', value: true },
                                    { name: 'Ангел', value: true },
                                ] },
                            { name: 'Автодилеры', value: false, params: [
                                    { name: 'Kamaz', value: false },
                                    { name: 'Toyota', value: false },
                                    { name: 'Nissan', value: false },
                                    { name: 'Hyundai', value: false },
                                    { name: 'Audi', value: true },
                                    { name: 'ГАЗ', value: true },
                                ] },
                            { name: 'Государственные системы', value: true, params: [
                                    { name: 'ГИБДД', value: true },
                                    { name: 'ГЛОНАСС', value: true },
                                    { name: 'АИС РСА', value: true },
                                ] }
                        ] },
                    { name: 'Информация о страховании ТС', value: false, dataList: [
                            { name: 'Сервис-провайдеры страховой телематики', value: true, params: [
                                    { name: 'T-One Group', value: false },
                                    { name: 'Р-Телематика', value: false },
                                    { name: 'Meta System', value: false },
                                ] },
                            { name: 'Государственные системы', value: true, params: [
                                    { name: 'ГИБДД', value: true },
                                    { name: 'ГЛОНАСС', value: true },
                                    { name: 'АИС РСА', value: true },
                                ] }
                        ] },
                    { name: 'Информация из систем мониторинга спецтранспорта', value: false, dataList: [
                            { name: 'Системы мониторинга транспортных средств МВД России', value: true, params: [] },
                            { name: 'Системы мониторинга транспортных средств МЧС России', value: true, params: [] },
                            { name: 'КИАСК-ТС Роскомнадзора', value: true, params: [] }
                        ] },
                    { name: 'Информация из систем мониторинга общественного транспорта', value: false, dataList: [
                            { name: 'Системы мониторинга транспорта', value: false, params: [
                                    { name: 'Gurtam', value: false },
                                    { name: 'Техноком', value: false },
                                    { name: 'Omnicomm', value: false },
                                    { name: 'Форт Телеком', value: false },
                                ] },
                            { name: 'Региональная навигационно-информационная система (РНИС)', value: false, params: [] }
                        ] },
                    { name: 'Информация V2x', value: false, dataList: [
                            { name: '', value: false, params: [] }
                        ] },
                ],
                more: [
                    { name: 'Оптимизация использования', value: true },
                    { name: 'Подтверждение достоверности данных', value: false },
                ],
                kpi: []
            },
            {
                id: 1,
                selection: {
                    title: '5. Выбранный регион: Ярославская область',
                    img: 'map.svg',
                    button: false
                },
                diagnostic: [
                    { name: 'Информирование о состоянии', value: false },
                    { name: 'Упреждающее обслуживание', value: false },
                    { name: 'Предупреждение об аномалиях', value: false },
                    { name: 'Прогноз срока обслуживания', value: false }
                ],
                category: [
                    { name: 'Навигационная информация о ТС', value: true, dataList: [
                            { name: 'Телематические платформы автопроизводителей', value: true, params: [
                                    { name: 'Kamaz', value: true },
                                    { name: 'Toyota', value: true },
                                    { name: 'Nissan', value: true },
                                    { name: 'Hyundai', value: true },
                                    { name: 'Audi', value: true },
                                    { name: 'ГАЗ', value: true },
                                ] },
                            { name: 'Системы мониторинга транспорта', value: false, params: [
                                    { name: 'Gurtam', value: false },
                                    { name: 'Техноком', value: false },
                                    { name: 'Omnicomm', value: false },
                                    { name: 'Форт Телеком', value: false },
                                ] },
                            { name: 'Сервис-провайдеры страховой телематики', value: true, params: [
                                    { name: 'T-One Group', value: false },
                                    { name: 'Р-Телематика', value: false },
                                    { name: 'Meta System', value: false },
                                ] },
                            { name: 'Охранно-поисковые системы', value: false, params: [
                                    { name: 'Starline', value: false },
                                    { name: 'Мега-Ф', value: false },
                                    { name: 'Пандора', value: false },
                                ] },
                            { name: 'Операторы сервисов каршеринга', value: false, params: [
                                    { name: 'Anytime', value: false },
                                    { name: 'BelkaCar', value: false },
                                    { name: 'YouDrive', value: false },
                                    { name: 'Яндекс.Drive', value: false },
                                    { name: 'Car5', value: false },
                                    { name: 'Делимобиль', value: false },
                                    { name: 'Rentmee', value: false },
                                    { name: 'Lifcar', value: false },
                                    { name: 'TimCar', value: false },
                                ] }
                        ] },
                    { name: 'Информация о техническом состоянии ТС', value: false, dataList: [
                            { name: 'Телематические платформы автопроизводителей', value: true, params: [
                                    { name: 'Kamaz', value: true },
                                    { name: 'Toyota', value: true },
                                    { name: 'Nissan', value: true },
                                    { name: 'Hyundai', value: true },
                                    { name: 'Audi', value: true },
                                    { name: 'ГАЗ', value: true },
                                ] },
                            { name: 'Системы мониторинга транспорта', value: false, params: [
                                    { name: 'Gurtam', value: false },
                                    { name: 'Техноком', value: false },
                                    { name: 'Omnicomm', value: false },
                                    { name: 'Форт Телеком', value: false },
                                ] },
                            { name: 'Сервис-провайдеры страховой телематики', value: true, params: [
                                    { name: 'T-One Group', value: false },
                                    { name: 'Р-Телематика', value: false },
                                    { name: 'Meta System', value: false },
                                ] },
                            { name: 'Охранно-поисковые системы', value: false, params: [
                                    { name: 'Starline', value: false },
                                    { name: 'Мега-Ф', value: false },
                                    { name: 'Пандора', value: false },
                                ] },
                            { name: 'Операторы сервисов каршеринга', value: false, params: [
                                    { name: 'Anytime', value: false },
                                    { name: 'BelkaCar', value: false },
                                    { name: 'YouDrive', value: false },
                                    { name: 'Яндекс.Drive', value: false },
                                    { name: 'Car5', value: false },
                                    { name: 'Делимобиль', value: false },
                                    { name: 'Rentmee', value: false },
                                    { name: 'Lifcar', value: false },
                                    { name: 'TimCar', value: false },
                                ] }
                        ] },
                    { name: 'Информация об истории обслуживания ТС', value: false, dataList: [
                            { name: 'Операторы технической помощи', value: true, params: [
                                    { name: 'Российское Автомобильное Товарищество', value: true },
                                    { name: 'Российский АвтоМотоКлуб', value: true },
                                    { name: 'Ангел', value: true },
                                ] },
                            { name: 'Автодилеры', value: false, params: [
                                    { name: 'Kamaz', value: false },
                                    { name: 'Toyota', value: false },
                                    { name: 'Nissan', value: false },
                                    { name: 'Hyundai', value: false },
                                    { name: 'Audi', value: true },
                                    { name: 'ГАЗ', value: true },
                                ] },
                            { name: 'Государственные системы', value: true, params: [
                                    { name: 'ГИБДД', value: true },
                                    { name: 'ГЛОНАСС', value: true },
                                    { name: 'АИС РСА', value: true },
                                ] }
                        ] },
                    { name: 'Информация о страховании ТС', value: false, dataList: [
                            { name: 'Сервис-провайдеры страховой телематики', value: true, params: [
                                    { name: 'T-One Group', value: false },
                                    { name: 'Р-Телематика', value: false },
                                    { name: 'Meta System', value: false },
                                ] },
                            { name: 'Государственные системы', value: true, params: [
                                    { name: 'ГИБДД', value: true },
                                    { name: 'ГЛОНАСС', value: true },
                                    { name: 'АИС РСА', value: true },
                                ] }
                        ] },
                    { name: 'Информация из систем мониторинга спецтранспорта', value: false, dataList: [
                            { name: 'Системы мониторинга транспортных средств МВД России', value: true, params: [] },
                            { name: 'Системы мониторинга транспортных средств МЧС России', value: true, params: [] },
                            { name: 'КИАСК-ТС Роскомнадзора', value: true, params: [] }
                        ] },
                    { name: 'Информация из систем мониторинга общественного транспорта', value: false, dataList: [
                            { name: 'Системы мониторинга транспорта', value: false, params: [
                                    { name: 'Gurtam', value: false },
                                    { name: 'Техноком', value: false },
                                    { name: 'Omnicomm', value: false },
                                    { name: 'Форт Телеком', value: false },
                                ] },
                            { name: 'Региональная навигационно-информационная система (РНИС)', value: false, params: [] }
                        ] },
                    { name: 'Информация V2x', value: false, dataList: [
                            { name: '', value: false, params: [] }
                        ] },
                ],
                more: [
                    { name: 'Оптимизация использования', value: true },
                    { name: 'Подтверждение достоверности данных', value: false },
                ],
                kpi: []
            },
            {
                id: 3,
                selection: {
                    title: '',
                    img: 'car_history_service.png',
                    button: false
                },
                diagnostic: [
                    { name: 'Информирование о состоянии', value: false },
                    { name: 'Упреждающее обслуживание', value: false },
                    { name: 'Предупреждение об аномалиях', value: false },
                    { name: 'Прогноз срока обслуживания', value: false }
                ],
                category: [
                    { name: 'Навигационная информация о ТС', value: true, dataList: [
                            { name: 'Телематические платформы автопроизводителей', value: true, params: [
                                    { name: 'Kamaz', value: true },
                                    { name: 'Toyota', value: true },
                                    { name: 'Nissan', value: true },
                                    { name: 'Hyundai', value: true },
                                    { name: 'Audi', value: true },
                                    { name: 'ГАЗ', value: true },
                                ] },
                            { name: 'Системы мониторинга транспорта', value: false, params: [
                                    { name: 'Gurtam', value: false },
                                    { name: 'Техноком', value: false },
                                    { name: 'Omnicomm', value: false },
                                    { name: 'Форт Телеком', value: false },
                                ] },
                            { name: 'Сервис-провайдеры страховой телематики', value: true, params: [
                                    { name: 'T-One Group', value: false },
                                    { name: 'Р-Телематика', value: false },
                                    { name: 'Meta System', value: false },
                                ] },
                            { name: 'Охранно-поисковые системы', value: false, params: [
                                    { name: 'Starline', value: false },
                                    { name: 'Мега-Ф', value: false },
                                    { name: 'Пандора', value: false },
                                ] },
                            { name: 'Операторы сервисов каршеринга', value: false, params: [
                                    { name: 'Anytime', value: false },
                                    { name: 'BelkaCar', value: false },
                                    { name: 'YouDrive', value: false },
                                    { name: 'Яндекс.Drive', value: false },
                                    { name: 'Car5', value: false },
                                    { name: 'Делимобиль', value: false },
                                    { name: 'Rentmee', value: false },
                                    { name: 'Lifcar', value: false },
                                    { name: 'TimCar', value: false },
                                ] }
                        ] },
                    { name: 'Информация о техническом состоянии ТС', value: false, dataList: [
                            { name: 'Телематические платформы автопроизводителей', value: true, params: [
                                    { name: 'Kamaz', value: true },
                                    { name: 'Toyota', value: true },
                                    { name: 'Nissan', value: true },
                                    { name: 'Hyundai', value: true },
                                    { name: 'Audi', value: true },
                                    { name: 'ГАЗ', value: true },
                                ] },
                            { name: 'Системы мониторинга транспорта', value: false, params: [
                                    { name: 'Gurtam', value: false },
                                    { name: 'Техноком', value: false },
                                    { name: 'Omnicomm', value: false },
                                    { name: 'Форт Телеком', value: false },
                                ] },
                            { name: 'Сервис-провайдеры страховой телематики', value: true, params: [
                                    { name: 'T-One Group', value: false },
                                    { name: 'Р-Телематика', value: false },
                                    { name: 'Meta System', value: false },
                                ] },
                            { name: 'Охранно-поисковые системы', value: false, params: [
                                    { name: 'Starline', value: false },
                                    { name: 'Мега-Ф', value: false },
                                    { name: 'Пандора', value: false },
                                ] },
                            { name: 'Операторы сервисов каршеринга', value: false, params: [
                                    { name: 'Anytime', value: false },
                                    { name: 'BelkaCar', value: false },
                                    { name: 'YouDrive', value: false },
                                    { name: 'Яндекс.Drive', value: false },
                                    { name: 'Car5', value: false },
                                    { name: 'Делимобиль', value: false },
                                    { name: 'Rentmee', value: false },
                                    { name: 'Lifcar', value: false },
                                    { name: 'TimCar', value: false },
                                ] }
                        ] },
                    { name: 'Информация об истории обслуживания ТС', value: false, dataList: [
                            { name: 'Операторы технической помощи', value: true, params: [
                                    { name: 'Российское Автомобильное Товарищество', value: true },
                                    { name: 'Российский АвтоМотоКлуб', value: true },
                                    { name: 'Ангел', value: true },
                                ] },
                            { name: 'Автодилеры', value: false, params: [
                                    { name: 'Kamaz', value: false },
                                    { name: 'Toyota', value: false },
                                    { name: 'Nissan', value: false },
                                    { name: 'Hyundai', value: false },
                                    { name: 'Audi', value: true },
                                    { name: 'ГАЗ', value: true },
                                ] },
                            { name: 'Государственные системы', value: true, params: [
                                    { name: 'ГИБДД', value: true },
                                    { name: 'ГЛОНАСС', value: true },
                                    { name: 'АИС РСА', value: true },
                                ] }
                        ] },
                    { name: 'Информация о страховании ТС', value: false, dataList: [
                            { name: 'Сервис-провайдеры страховой телематики', value: true, params: [
                                    { name: 'T-One Group', value: false },
                                    { name: 'Р-Телематика', value: false },
                                    { name: 'Meta System', value: false },
                                ] },
                            { name: 'Государственные системы', value: true, params: [
                                    { name: 'ГИБДД', value: true },
                                    { name: 'ГЛОНАСС', value: true },
                                    { name: 'АИС РСА', value: true },
                                ] }
                        ] },
                    { name: 'Информация из систем мониторинга спецтранспорта', value: false, dataList: [
                            { name: 'Системы мониторинга транспортных средств МВД России', value: true, params: [] },
                            { name: 'Системы мониторинга транспортных средств МЧС России', value: true, params: [] },
                            { name: 'КИАСК-ТС Роскомнадзора', value: true, params: [] }
                        ] },
                    { name: 'Информация из систем мониторинга общественного транспорта', value: false, dataList: [
                            { name: 'Системы мониторинга транспорта', value: false, params: [
                                    { name: 'Gurtam', value: false },
                                    { name: 'Техноком', value: false },
                                    { name: 'Omnicomm', value: false },
                                    { name: 'Форт Телеком', value: false },
                                ] },
                            { name: 'Региональная навигационно-информационная система (РНИС)', value: false, params: [] }
                        ] },
                    { name: 'Информация V2x', value: false, dataList: [
                            { name: '', value: false, params: [] }
                        ] },
                ],
                more: [
                    { name: 'Оптимизация использования', value: true },
                    { name: 'Подтверждение достоверности данных', value: false },
                ],
                kpi: []
            }
        ];
    }
    ConfigService.prototype.saveConfig = function (id, configName, value) {
        this.configs[configName] = value;
        // alert('Конфигурация сохранена');
    };
    ConfigService.prototype.getConfig = function (id) {
        var config = this.configs.filter(function (conf) { return conf.id === id; });
        if (config.length === 0) {
            return this.configs[0];
        }
        else {
            return config[0];
        }
    };
    ConfigService.prototype.getConfigCategory = function (id, category) {
        return this.configs.filter(function (conf) { return conf.id === parseInt(id); })[0][category];
    };
    ConfigService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
