import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

import { CartService } from '@/_services';
import { SearchService } from '@/_services';
import {componentRefresh} from '@angular/core/src/render3/instructions';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less']
})

export class SearchComponent implements OnInit {

  searchFilter = {
    title: '',
    otr: undefined,
    industry: undefined,
    region: undefined
  };

  cartAmount = 0;
  cart: any[] = [];

  constructor(
    private cartService: CartService,
    private searchService: SearchService,
    private router: Router
  ) {

    this.cartService.CartState.subscribe((cart) => {
      this.cart = cart.products;
    });

  }

  ngOnInit() {
    this.cartAmount = this.cartService.getNumberOfProducts();
    this.cart = this.cartService.getCart();
  }

  search() {
    this.searchService.setParams(this.searchFilter);
    this.router.navigate(['/search']);
  }

}
