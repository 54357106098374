import { Component, OnInit } from '@angular/core';

import { CartService } from '@/_services';
import { SearchService } from '@/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-block',
  templateUrl: './search-block.component.html',
  styleUrls: ['./search-block.component.less']
})
export class SearchBlockComponent implements OnInit {

  departments = [
    {value: 1, viewValue: 'Финансы'},
    {value: 2, viewValue: 'Управление'},
    {value: 3, viewValue: 'Транспорт'},
    {value: 4, viewValue: 'Здравоохранение'}
  ];

  regions = [
    {value: 1, viewValue: 'Москва'},
    {value: 2, viewValue: 'Ленинградская область'},
    {value: 3, viewValue: 'Краснодарский край'}
  ];

  searchFilter = {
    title: '',
    department: undefined,
    region: undefined
  };

  cart: any[] = [];

  constructor(
    private searchService: SearchService,
    private cartService: CartService,
    private router: Router
  ) {

    this.cartService.CartState.subscribe((cart) => {
      this.cart = cart.products;
    });

    this.searchService.searchQuery.subscribe((searchQuery) => {
      this.searchFilter = searchQuery.query;
    });

  }

  ngOnInit() {
    this.cart = this.cartService.getCart();
    this.searchFilter = this.searchService.getSearchFilter();
  }

  search() {
    this.searchService.setParams({query: this.searchFilter});
    this.router.navigate(['/search']);
  }

}
