import { Injectable } from '@angular/core';
import {SearchService} from '@/_services/search.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private searchService: SearchService
  ) { }

  getById(id) {
    return this.searchService.getById(parseInt(id));
  }
}
