import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var CartService = /** @class */ (function () {
    function CartService() {
        this.cartSubject = new Subject();
        this.Products = [];
        this.CartState = this.cartSubject.asObservable();
    }
    CartService.prototype.addProduct = function (product) {
        console.log('in service');
        this.Products.push(product);
        this.cartSubject.next({ loaded: true, products: this.Products });
    };
    CartService.prototype.removeProduct = function (id) {
        this.Products = this.Products.filter(function (item) { return item.id !== id; });
        this.cartSubject.next({ loaded: false, products: this.Products });
    };
    CartService.prototype.getNumberOfProducts = function () {
        return this.Products.length;
    };
    CartService.prototype.getCart = function () {
        return this.Products;
    };
    CartService.prototype.inCart = function (id) {
        return this.Products.some(function (product) { return product.id === id; });
    };
    CartService.ngInjectableDef = i0.defineInjectable({ factory: function CartService_Factory() { return new CartService(); }, token: CartService, providedIn: "root" });
    return CartService;
}());
export { CartService };
