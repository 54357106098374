<header class="header">
  <div class="app-title">Платформа «АвтоДата»</div>
  <div class="item">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#0A2132"/>
      <circle cx="12" cy="8" r="2.5" stroke="#1871BD"/>
      <path d="M14.6891 12.0092C14.9807 11.8482 15.197 11.685 15.3441 11.5574L17.4396 12.1276L17.145 14.0915L15.785 14.739L15.5 14.8748V15.1905V17.5811C15.4886 17.5877 15.4767 17.5946 15.4644 17.6016C15.2934 17.6993 15.0381 17.8322 14.7111 17.9656C14.056 18.233 13.1208 18.5 12 18.5C10.8792 18.5 9.94398 18.233 9.28895 17.9656C8.96189 17.8322 8.70664 17.6993 8.53557 17.6016C8.52327 17.5946 8.51141 17.5877 8.5 17.5811V15.1905V14.8748L8.21497 14.739L6.85503 14.0915L6.56045 12.1276L8.68145 11.5504C8.6938 11.5599 8.70663 11.5695 8.71995 11.5795C8.882 11.7003 9.11725 11.8581 9.41951 12.0151C10.024 12.3291 10.9028 12.6429 12 12.6429C13.2184 12.6429 14.1012 12.3338 14.6891 12.0092Z" stroke="#1871BD"/>
    </svg>

  </div>
  <div class="item short-cart"> <app-short-cart></app-short-cart> </div>
  <div class="item"> <a>справочник</a> </div>

  <div class="item"> <a>документация</a> </div>
</header>
