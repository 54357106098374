import { Component, OnInit } from '@angular/core';
import { CartService, SearchService } from '@/_services';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.less']
})
export class SearchResultsComponent implements OnInit {

  cart = [];

  searchResults$ = [];

  constructor(
    private searchService: SearchService,
    private cartService: CartService
  ) {

    this.searchService.searchResult.subscribe(foundProducts => {
      this.searchResults$ = foundProducts.products;
    });

  }

  ngOnInit() {
    this.searchResults$ = this.searchService.getResults();
  }

  inCart(id) {
    return this.cartService.inCart(id);
  }

  toggleProductInCart(product) {
    if (!this.inCart(product.id)) {
      this.cartService.addProduct(product);
    } else {
      this.cartService.removeProduct(product.id);
    }
  }

}
