import { Component, OnInit } from '@angular/core';
import {CartService} from '@/_services';

@Component({
  selector: 'app-short-cart',
  templateUrl: './short-cart.component.html',
  styleUrls: ['./short-cart.component.less']
})
export class ShortCartComponent implements OnInit {

  cart: any[] = [];

  constructor(
    private cartService: CartService
  ) {

    this.cartService.CartState.subscribe((cart) => {
      this.cart = cart.products;
    });

  }

  ngOnInit() {
    this.cart = this.cartService.getCart();
  }

  getPrice() {
    return this.cart.reduce((total, product) => total + product.price, 0);
  }

}
