<div class="side-content">
  <button title="На главную" [routerLink]="['/search']" routerLinkActive="active">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM20.2353 13.3441L15.8235 9.37353L11.4118 13.3441V20.2353H13.1765V14.9412H18.4706V20.2353H20.2353V13.3441ZM7 14.9412L15.8235 7L24.6471 14.9412H22V22H16.7059V16.7059H14.9412V22H9.64706V14.9412H7Z"/>
    </svg>
  </button>
  <button title="История заказов" [routerLink]="['/history']" routerLinkActive="active">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.9" fill-rule="evenodd" clip-rule="evenodd" d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM9.725 11.7895C10.925 9.5 13.25 8 15.875 8C19.775 8 23 11.3947 23 15.5C23 19.6053 19.85 23 15.875 23C12.575 23 9.8 20.6316 8.975 17.4737H10.55C11.375 19.7632 13.4 21.4211 15.875 21.4211C18.95 21.4211 21.5 18.7368 21.5 15.5C21.5 12.2632 18.95 9.57895 15.875 9.57895C13.625 9.57895 11.75 10.9211 10.775 12.8947L12.875 15.1053H8V9.97368L9.725 11.7895ZM14.75 15.9737V11.9474H15.875V15.2632L18.875 17.1579L18.275 18.1842L14.75 15.9737Z"/>
    </svg>
  </button>
  <img src="/assets/sidebar-flow.svg" alt="" class="sidebar-flow-img">
</div>
