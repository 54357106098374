  <div class="history-page">
    <app-search-block></app-search-block>
    <h1>История заказов</h1>
    <div class="products-list">
      <div class="product-item" *ngFor="let product of searchResults">
        <div class="product-item--info" [routerLink]="['/product', product.id]">
          <div class="product-image">
            <img src="/assets/{{product.img}}">
          </div>
          <div class="info">
            <div class="title">{{product.name}}</div>
            <div class="description">{{product.description}}</div>
          </div>
          <div class="price">
              {{product.status}}<br />
            <span>{{product.expDate}}</span>
          </div>
        </div>
        <div class="cart">
          <button
            class="config"
            title="Настроить"
            *ngIf="product.expDate"
            [routerLink]="['/constructor', product.id]">
            Настроить
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.2257 8.49915C11.2257 10.0009 10.003 11.2241 8.50057 11.2241C6.99926 11.2241 5.77517 10.0009 5.77517 8.49915C5.77517 6.99828 6.99926 5.77682 8.50057 5.77682C10.0039 5.77768 11.2257 6.99828 11.2257 8.49915ZM14.6639 6.55076L14.2345 5.50895C14.2345 5.50895 15.2336 3.20849 15.1444 3.11968L13.8232 1.80191C13.7309 1.71079 11.4781 2.76096 11.4781 2.76096L10.4383 2.33218C10.4383 2.33218 9.52026 5.83636e-08 9.39407 5.83636e-08H7.52831C7.3993 5.83636e-08 6.54869 2.33766 6.54869 2.33766L5.51122 2.76731C5.51122 2.76731 3.21069 1.76875 3.12205 1.85756L1.80254 3.17763C1.71107 3.26817 2.76154 5.52307 2.76154 5.52307L2.33243 6.56286C2.33243 6.56286 0 7.48068 0 7.60439V9.47175C0 9.6018 2.33779 10.4527 2.33779 10.4527L2.7669 11.4911C2.7669 11.4911 1.76867 13.7901 1.85647 13.8783L3.17767 15.1996C3.26688 15.2892 5.52138 14.2393 5.52138 14.2393L6.56169 14.6696C6.56169 14.6696 7.47975 17 7.60707 17H9.47255C9.60157 17 10.4522 14.6623 10.4522 14.6623L11.4933 14.2321C11.4933 14.2321 13.7893 15.2313 13.8782 15.1439L15.2 13.8238C15.2912 13.7318 14.2387 11.4781 14.2387 11.4781L14.6667 10.4386C14.6667 10.4386 17 9.51932 17 9.39389V7.52741C17.0008 7.39909 14.6639 6.55076 14.6639 6.55076Z" fill="#091F2F"/>
            </svg>
          </button>
         </div>
      </div>
    </div>
  </div>
