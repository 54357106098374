/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./search-block/search-block.component.ngfactory";
import * as i3 from "./search-block/search-block.component";
import * as i4 from "../_services/search.service";
import * as i5 from "../_services/cart.service";
import * as i6 from "@angular/router";
import * as i7 from "./search-results/search-results.component.ngfactory";
import * as i8 from "./search-results/search-results.component";
import * as i9 from "./search-results-filter/search-results-filter.component.ngfactory";
import * as i10 from "./search-results-filter/search-results-filter.component";
import * as i11 from "./search.component";
var styles_SearchComponent = [i0.styles];
var RenderType_SearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchComponent, data: {} });
export { RenderType_SearchComponent as RenderType_SearchComponent };
export function View_SearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "search-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["style", "padding-right: 20px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-search-block", [], null, null, null, i2.View_SearchBlockComponent_0, i2.RenderType_SearchBlockComponent)), i1.ɵdid(3, 114688, null, 0, i3.SearchBlockComponent, [i4.SearchService, i5.CartService, i6.Router], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-search-results", [], null, null, null, i7.View_SearchResultsComponent_0, i7.RenderType_SearchResultsComponent)), i1.ɵdid(5, 114688, null, 0, i8.SearchResultsComponent, [i4.SearchService, i5.CartService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-search-results-filter", [], null, null, null, i9.View_SearchResultsFilterComponent_0, i9.RenderType_SearchResultsFilterComponent)), i1.ɵdid(8, 114688, null, 0, i10.SearchResultsFilterComponent, [i4.SearchService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 8, 0); }, null); }
export function View_SearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search", [], null, null, null, View_SearchComponent_0, RenderType_SearchComponent)), i1.ɵdid(1, 114688, null, 0, i11.SearchComponent, [i5.CartService, i4.SearchService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchComponentNgFactory = i1.ɵccf("app-search", i11.SearchComponent, View_SearchComponent_Host_0, {}, {}, []);
export { SearchComponentNgFactory as SearchComponentNgFactory };
