import { Component, OnInit } from '@angular/core';
import { CartService, ProductsService } from '@/_services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.less']
})
export class ProductComponent implements OnInit {

  product;

  constructor(
    private productsService: ProductsService,
    private cartService: CartService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.product = this.productsService.getById(this.route.snapshot.paramMap.get('id'));
  }

  inCart(id) {
    return this.cartService.inCart(id);
  }

  addProductToCart(product) {
    this.cartService.addProduct(product);
  }

  removeProductFromCart(id) {
    this.cartService.removeProduct(id);
  }

  toggleProductInCart(product) {
    if (this.inCart(product.id)) {
      this.removeProductFromCart(product.id);
    } else {
      this.addProductToCart(product);
    }
  }

}
