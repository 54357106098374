<div class="aside-right">
    <h3>Критерии для выбора сервисов</h3>
  <div class="filters-block">
    <h4>Методы аналитики данных</h4>
    <ul>
      <li><mat-checkbox class="small square" [(ngModel)]="searchFilter.analyticsType.classic" (ngModelChange)="toggleFilters()">диагностическая</mat-checkbox></li>
      <li><mat-checkbox class="small square" [(ngModel)]="searchFilter.analyticsType.predictive" (ngModelChange)="toggleFilters()">предсказательная</mat-checkbox></li>
      <li><mat-checkbox class="small square" [(ngModel)]="searchFilter.analyticsType.prescriptive" (ngModelChange)="toggleFilters()">предписывающая</mat-checkbox></li>
    </ul>
  </div>
  <div class="filters-block">
    <h4>Метод передачи данных сервис-провайдеру</h4>
    <ul>
      <li><mat-checkbox class="small square" [(ngModel)]="searchFilter.transferType.package" (ngModelChange)="toggleFilters()">пакетный</mat-checkbox></li>
      <li><mat-checkbox class="small square" [(ngModel)]="searchFilter.transferType.stream" (ngModelChange)="toggleFilters()">потоковый</mat-checkbox></li>
    </ul>
  </div>
  <div class="filters-block">
    <h4>Типы передаваемых сервис-провайдеру данных</h4>
    <ul>
      <li><mat-checkbox class="small square" [(ngModel)]="searchFilter.dataType.asIs" (ngModelChange)="toggleFilters()" >сырые</mat-checkbox></li>
      <li><mat-checkbox class="small square" [(ngModel)]="searchFilter.dataType.normalised" (ngModelChange)="toggleFilters()">нормализированные</mat-checkbox></li>
      <li><mat-checkbox class="small square" [(ngModel)]="searchFilter.dataType.enriched" (ngModelChange)="toggleFilters()">обогащенные</mat-checkbox></li>
      <li><mat-checkbox class="small square" [(ngModel)]="searchFilter.dataType.processed" (ngModelChange)="toggleFilters()">обработанные</mat-checkbox></li>
    </ul>
  </div>
  <div class="filters-block">
    <h4>Способы оплаты</h4>
    <ul>
      <li><mat-checkbox class="small square" [(ngModel)]="searchFilter.paymentType.monthly" (ngModelChange)="toggleFilters()">За период времени</mat-checkbox></li>
      <li><mat-checkbox class="small square" [(ngModel)]="searchFilter.paymentType.perService" (ngModelChange)="toggleFilters()">За использованные ресурсы</mat-checkbox></li>
      <li><mat-checkbox class="small square" [(ngModel)]="searchFilter.paymentType.partly" (ngModelChange)="toggleFilters()">Рассрочка</mat-checkbox></li>
    </ul>
  </div>
</div>
