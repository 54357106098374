import { Component, OnInit } from '@angular/core';
import { SearchService } from '@/_services';
import { FilterObject } from '@/_models';

@Component({
  selector: 'app-search-results-filter',
  templateUrl: './search-results-filter.component.html',
  styleUrls: ['./search-results-filter.component.less']
})
export class SearchResultsFilterComponent implements OnInit {

  searchFilter = new FilterObject();

  constructor(
    private searchService: SearchService
  ) {

    this.searchFilter = {
      analyticsType: { classic: false, predictive: false, prescriptive: false },
      transferType: { package: false, stream: false },
      dataType: { asIs: false, normalised: false, enriched: false, processed: false },
      paymentType: { monthly: false, perService: false, partly: false }
    };

  }

  ngOnInit() {
    this.searchService.filterObject.subscribe(filterObject => {
      this.searchFilter = filterObject;
    });
  }

  toggleFilters() {
    this.searchService.toggleFilters(this.searchFilter);
  }

}
