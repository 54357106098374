<div class="configuration-page">
  <div class="configs">
    <div class="col-1">
      <h3>Категория данных</h3>
      <div class="coll-content">
        <div *ngFor="let cat of configCategory; index as i" class="item" [ngClass]="(i===selectedCategoryIndex)?'selected':''">
          <mat-checkbox [(ngModel)]="configCategory[i].value"></mat-checkbox>
          <span class="label" (click)="selectCategory(i)">
          <span>{{cat.name}}</span>
        </span>
        </div>
      </div>
    </div>
    <div class="col-2">
      <h3>Поставщик данных</h3>
      <div class="coll-content">
        <div class="item" *ngFor="let subCategory of configCategory[selectedCategoryIndex].dataList; index as i" [ngClass]="(i===selectedSubCategoryIndex)?'selected':''">
          <mat-checkbox
            [(ngModel)]="configCategory[selectedCategoryIndex].dataList[i].value"></mat-checkbox>
          <span class="label" (click)="selectSubCategory(i)">
          <span>{{subCategory.name}}</span>
        </span>
        </div>
      </div>
    </div>
    <div class="col-3">
      <h3>Источник данных</h3>
      <div class="coll-content">
        <div class="item" *ngFor="let param of configCategory[selectedCategoryIndex].dataList[selectedSubCategoryIndex].params; index as i">
          <mat-checkbox class="square"
            [(ngModel)]="configCategory[selectedCategoryIndex].dataList[selectedSubCategoryIndex].params[i].value"
            (ngModelChange)="checkParentSelected()">
            {{param.name}}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="bottom-config">
      <label class="title">Глубина исходных данных </label>
      <mat-form-field>
        <mat-select value="месяц" [(value)]="selectedDeep">
          <mat-option *ngFor="let d of deep" [value]="d">{{d}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button class="save" (click)="saveConfig()">Сохранить</button>
    </div>
  </div>

</div>
