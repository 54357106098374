<div class="constructor-page">

  <div class="page">

    <div class="col-1">
      <h1 class="page-title">Настройка сервиса: <br /><span style=" font-size: 18px">{{product.name}}</span></h1>
      <div class="config-block">
        <h4 class="first">1. Предиктивная диагностика</h4>
        <div class="block-content">
          <ul>
            <li *ngFor="let conf of config.diagnostic;"><mat-checkbox class="small square" [(ngModel)]="conf.value" (ngModelChange)="recountPrice()">{{conf.name}}</mat-checkbox></li>
          </ul>
        </div>
      </div>
      <div class="config-block">
        <hr>
        <h4>2. Категория данных
          <span class="conf-icon" (click)="configuration=true">
            настроить
          </span>
        </h4>
        <div class="block-content">
          <ul class="cols-2">
            <li *ngFor="let conf of config.category;"><mat-checkbox class="small square" [(ngModel)]="conf.value" (ngModelChange)="recountPrice()">{{conf.name}}</mat-checkbox></li>
          </ul>
        </div>

      </div>
      <div class="config-block">
        <hr>
        <h4>3. Дополнительные услуги</h4>
        <div class="block-content">
          <ul>
            <li *ngFor="let conf of config.more;"><mat-checkbox class="small square" [(ngModel)]="conf.value" (ngModelChange)="recountPrice()">{{conf.name}}</mat-checkbox></li>
          </ul>
        </div>
      </div>
      <div class="config-block sliders">
        <hr>
        <h4>4. Время обновления данных</h4>
        <div class="block-content">
          <ul>
            <li>
              <mat-slider
                style="margin-top: 15px"
                thumbLabel
                [displayWith]="formatLabel"
                min="1"
                max="5"
                step="1"
                value="3">

              </mat-slider>
            </li>
          </ul>
        </div>

      </div>

    </div>
    <div class="col-2">
      <div class="coll-block">
        <h4 *ngIf="config.selection.title">{{config.selection.title}}</h4>
        <div class="car-parts">
          <img [ngClass]="(config.selection.img.indexOf('map') > -1) ? 'map':''" src="/assets/{{config.selection.img}}">
          <div class="buttons-control" *ngIf="config.selection.button">
            <button class="invert">ходовая</button>
            <button class="invert">двигатель</button>
            <button class="invert">трансмиссия</button>
            <button class="invert">электрика</button>
          </div>
        </div>
      </div>
      <div class="coll-block">
        <div class="recommendations-block">
          <div class="recommendations-block--title">Рекомендации</div>
          <div class="recommendations-block--list">
            <div class="recommendation-item">
              <div class="recommendation-message">
                Добавление данных от АСУ ТК приведет к увеличению точности на 5% и стоимости на 500 руб
              </div>
              <div class="recommendation-accept">
                <button class="{{!priceButtons[0].activated && 'invert'}}" (click)="toggleCost(priceButtons[0])">{{priceButtons[0].activated ? 'отменить' : 'применить'}}</button>
              </div>
            </div>
            <div class="recommendation-item">
              <div class="recommendation-message">
                Данные от Автопроизводителя №1 используются в 3% случаев и стоят 1200 руб, можно удалить
              </div>
              <div class="recommendation-accept">
                <button class="{{!priceButtons[1].activated && 'invert'}}" (click)="toggleCost(priceButtons[1])">{{priceButtons[1].activated ? 'отменить' : 'применить'}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="coll-block" style="border-top: 1px solid #cccccc">
        <div class="total-block">


          <div class="total-title">
            <span>Итоговая стоимость:</span>
          </div>
          <div class="total-price">
            <span>{{(totalPrice).toLocaleString()}} <span class="rubble">₽</span></span>
          </div>
          <div class="total-confirm">
            <button (click)="save()">Сохранить</button>
          </div>
        </div>
      </div>



    </div>
  </div>
  <app-configuration *ngIf="configuration" (configurationOut)="configuration=false; config.category = $event; recountPrice();"></app-configuration>
</div>
