import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.less']
})
export class HistoryComponent implements OnInit {

  searchResults = [{id: 1, img: 'road-service-img.svg', name: 'Информация о состоянии дорожной инфраструктуры',
    description: 'Информация о состоянии дорожной инфраструктуры на основании анализа данных, полученной из различных источников',
    items: ['', '', ''],
    department: [1, 2],
    region: [1, 3],

    analyticsType: ['classic', 'predictive', 'prescriptive'],
    transferType: ['package', 'stream'],
    dataType: ['enriched', 'processed'],
    paymentType: ['monthly', 'perService', 'partly'],

    modules: [],
    payments: ['service'] , price: 54990, subStatus: 'Активен до', expDate: '02.04.20'},
    {id: 2, img: 'insurance-service-img.svg', name: 'Страхование по запросу',
      description: 'Страхование автомобиля на конкретную поездку с учетом оценки опасности маршрута, погодных условий, дорожной обстановки и других данных. Реализация концепции "страховая биржа',
      items: ['', '', ''],
      department: [3],
      region: [1, 3],

      analyticsType: ['classic', 'predictive', 'prescriptive'],
      transferType: ['package', 'stream'],
      dataType: ['normalised'],
      paymentType: ['monthly', 'perService'],

      modules: [],
      payments: ['month'], price: 44990, subStatus: 'Активен до', expDate: ' 02.04.20'},
    {id: 3, img: 'history-service-img.svg', name: 'История автомобиля',
      description: 'Цифровая история автомобиля на основе объективных данных, полученных из различных источников',
      items: ['', '', ''],
      department: [1, 3],
      industry: [2, 3],
      region: [1],

      analyticsType: ['prescriptive'],
      transferType: ['stream'],
      dataType: ['asIs', 'normalised', 'enriched', 'processed'],
      paymentType: ['monthly', 'perService'],

      modules: [],
      payments: ['month'], price: 34990, subStatus: 'Куплен', expDate: ''},
    {id: 4, img: 'repair-service-img.svg', name: 'Предиктивная (прогнозная) диагностика ТС',
      description: 'Предиктивная (прогнозная) диагностика ТС на основе аналитики данных, полученных от подключенных ТС и других источников информации',
      items: ['', '', ''],
      department: [3],
      industry: [1, 3],
      region: [3],

      analyticsType: ['classic'],
      transferType: ['package'],
      dataType: ['asIs', 'normalised'],
      paymentType: ['perService'],

      modules: [],
      payments: ['service'], price: 24990, subStatus: 'Куплен', expDate: ''},
    {id: 5, img: 'sharing-service-img.svg', name: 'Совместное использование автомобиля (car sharing), версия 2.0',
      description: 'Новая версия сервиса совместного использования автомобиля (car sharing) - обеспечивается гарантия добросовестности пользователей на основе анализа информации из разнородных источников',
      items: ['', '', ''],
      department: [ 2],
      industry: [1],
      region: [2, 3],

      analyticsType: ['classic'],
      transferType: ['package'],
      dataType: ['asIs', 'enriched', 'processed'],
      paymentType: ['monthly', 'partly'],

      modules: [],
      payments: ['part'], price: 14990, subStatus: 'Истек', expDate: '02.04.19'}
  ];

  constructor() { }

  ngOnInit() {
  }

}
