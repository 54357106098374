import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {CartComponent} from '@/cart/cart.component';
import {ProductComponent} from '@/product/product.component';
import {SearchComponent} from '@/search/search.component';
import {ConstructorComponent} from '@/constructor/constructor.component';
import {PaymentComponent} from '@/payment/payment.component';
import {ConfigurationComponent} from '@/configuration/configuration.component';
import {HistoryComponent} from '@/history/history.component';
import {OrderComponent} from '@/order/order.component';

const routes: Routes = [
  {
    path: 'cart',
    component: CartComponent
  },
  {
    path: 'product/:id',
    component: ProductComponent
  },
  {
    path: 'search',
    component: SearchComponent
  },
  {
    path: 'constructor/:id',
    component: ConstructorComponent
  },
  {
    path: 'payment',
    component: PaymentComponent
  },
  {
    path: 'configuration/:id',
    component: ConfigurationComponent
  },
  {
    path: 'history',
    component: HistoryComponent
  },
  {
    path: 'order',
    component: OrderComponent
  }, {
    path: '**',
    redirectTo: 'search'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
