import { SearchService } from '@/_services/search.service';
import * as i0 from "@angular/core";
import * as i1 from "./search.service";
var ProductsService = /** @class */ (function () {
    function ProductsService(searchService) {
        this.searchService = searchService;
    }
    ProductsService.prototype.getById = function (id) {
        return this.searchService.getById(parseInt(id));
    };
    ProductsService.ngInjectableDef = i0.defineInjectable({ factory: function ProductsService_Factory() { return new ProductsService(i0.inject(i1.SearchService)); }, token: ProductsService, providedIn: "root" });
    return ProductsService;
}());
export { ProductsService };
