import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductsService} from '@/_services';
import { ActivatedRoute } from '@angular/router';
import {ConfigService} from '@/_services/config.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.less']
})
export class ConfigurationComponent implements OnInit {

  @Output() configurationOut = new EventEmitter();

  product;
  configCategory;

  selectedCategoryIndex = 0;
  selectedSubCategoryIndex = 0;

  deep = [
    '-- не выбрано --', 'месяц', '3 месяца', '6 месяцев', '1 год'
  ];

  selectedDeep = '-- не выбрано --';

  constructor(
    private productsService: ProductsService,
    private configService: ConfigService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.product = this.productsService.getById(this.route.snapshot.paramMap.get('id'));
    this.configCategory = this.configService.getConfigCategory(this.product.id, 'category');
  }

  selectCategory(index) {
    this.selectedCategoryIndex = index;
    this.selectedSubCategoryIndex = 0;
  }

  selectSubCategory(index) {
    this.selectedSubCategoryIndex = index;
  }

  saveConfig() {
    this.configService.saveConfig(this.product.id, 'category', this.configCategory);
    this.configurationOut.emit(this.configCategory);
  }

  checkParentSelected() {
    if (this.configCategory[this.selectedCategoryIndex].dataList[this.selectedSubCategoryIndex].params.some(param => param.value)) {
      this.configCategory[this.selectedCategoryIndex].dataList[this.selectedSubCategoryIndex].value = true;
    } else {
      this.configCategory[this.selectedCategoryIndex].dataList[this.selectedSubCategoryIndex].value = false;
    }
  }

}
