/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./aside.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./aside.component";
var styles_AsideComponent = [i0.styles];
var RenderType_AsideComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AsideComponent, data: {} });
export { RenderType_AsideComponent as RenderType_AsideComponent };
export function View_AsideComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "side-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "button", [["routerLinkActive", "active"], ["title", "\u041D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, [[1, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), i1.ɵdid(4, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, ":svg:svg", [["fill", "none"], ["height", "32"], ["viewBox", "0 0 32 32"], ["width", "32"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, ":svg:path", [["clip-rule", "evenodd"], ["d", "M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM20.2353 13.3441L15.8235 9.37353L11.4118 13.3441V20.2353H13.1765V14.9412H18.4706V20.2353H20.2353V13.3441ZM7 14.9412L15.8235 7L24.6471 14.9412H22V22H16.7059V16.7059H14.9412V22H9.64706V14.9412H7Z"], ["fill-rule", "evenodd"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 7, "button", [["routerLinkActive", "active"], ["title", "\u0418\u0441\u0442\u043E\u0440\u0438\u044F \u0437\u0430\u043A\u0430\u0437\u043E\u0432"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, [[3, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(11, 1), i1.ɵdid(12, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(15, 0, null, null, 1, ":svg:svg", [["fill", "none"], ["height", "32"], ["viewBox", "0 0 32 32"], ["width", "32"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, ":svg:path", [["clip-rule", "evenodd"], ["d", "M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM9.725 11.7895C10.925 9.5 13.25 8 15.875 8C19.775 8 23 11.3947 23 15.5C23 19.6053 19.85 23 15.875 23C12.575 23 9.8 20.6316 8.975 17.4737H10.55C11.375 19.7632 13.4 21.4211 15.875 21.4211C18.95 21.4211 21.5 18.7368 21.5 15.5C21.5 12.2632 18.95 9.57895 15.875 9.57895C13.625 9.57895 11.75 10.9211 10.775 12.8947L12.875 15.1053H8V9.97368L9.725 11.7895ZM14.75 15.9737V11.9474H15.875V15.2632L18.875 17.1579L18.275 18.1842L14.75 15.9737Z"], ["fill-rule", "evenodd"], ["opacity", "0.9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "img", [["alt", ""], ["class", "sidebar-flow-img"], ["src", "/assets/sidebar-flow.svg"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "/search"); _ck(_v, 2, 0, currVal_0); var currVal_1 = "active"; _ck(_v, 4, 0, currVal_1); var currVal_2 = _ck(_v, 11, 0, "/history"); _ck(_v, 10, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 12, 0, currVal_3); }, null); }
export function View_AsideComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-aside", [], null, null, null, View_AsideComponent_0, RenderType_AsideComponent)), i1.ɵdid(1, 114688, null, 0, i3.AsideComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AsideComponentNgFactory = i1.ɵccf("app-aside", i3.AsideComponent, View_AsideComponent_Host_0, {}, {}, []);
export { AsideComponentNgFactory as AsideComponentNgFactory };
