import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { fakeBackendProvider } from './_helpers';
import { MomentModule } from 'ngx-moment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {AsideComponent} from '@/shared/components/aside/aside.component';
import {HeaderComponent} from '@/shared/components/header/header.component';
import {ModalDialogComponent} from '@/shared/components/modal-dialog/modal-dialog.component';
import {PageNotFoundComponent} from '@/shared/components/page-not-found/page-not-found.component';

import {SearchComponent} from '@/search/search.component';

import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import { CartComponent } from './cart/cart.component';
import { ModulesPipe } from './_pipes/modules.pipe';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ProductComponent } from './product/product.component';
import { SearchResultsComponent } from './search/search-results/search-results.component';
import { ConstructorComponent } from './constructor/constructor.component';
import { PaymentComponent } from './payment/payment.component';
import {SearchBlockComponent} from '@/search/search-block/search-block.component';
import {SearchResultsFilterComponent} from '@/search/search-results-filter/search-results-filter.component';
import {MatTabsModule} from '@angular/material/tabs';
import { ConfigurationComponent } from './configuration/configuration.component';
import { HistoryComponent } from './history/history.component';
import { OrderComponent } from './order/order.component';
import { ShortOrderComponent } from './order/short-order/short-order.component';
import { FilterByPipe } from './_pipes/filter-by.pipe';
import { SortByPipe } from './_pipes/sort-by.pipe';
import {SafeHtmlPipe} from '@/_pipes/safe-html.pipe';
import {WelcomeComponent} from '@/welcome/welcome.component';
import { ShortCartComponent } from './cart/short-cart/short-cart.component';
import { MapToIterable } from './_pipes/map-to-iterable.pipe';

// import { JwtInterceptor, ErrorInterceptor } from './_helpers';

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    AsideComponent,
    HeaderComponent,
    ModalDialogComponent,
    PageNotFoundComponent,
    CartComponent,
    ModulesPipe,
    ProductComponent,
    SearchResultsComponent,
    ConstructorComponent,
    PaymentComponent,
    SearchBlockComponent,
    SearchResultsFilterComponent,
    ConfigurationComponent,
    HistoryComponent,
    OrderComponent,
    ShortOrderComponent,
    FilterByPipe,
    SortByPipe,
    SafeHtmlPipe,
    WelcomeComponent,
    ShortCartComponent,
    MapToIterable
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatCheckboxModule,
    FormsModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatTabsModule,
    MomentModule.forRoot({ relativeTimeThresholdOptions: { m: 59 } })
  ],
  providers: [
    // {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    // {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    fakeBackendProvider,
    ModulesPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
