import { Component, OnInit } from '@angular/core';
import {CartService, ProductsService, SearchService} from '@/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '@/_services/config.service';

@Component({
  selector: 'app-constructor',
  templateUrl: './constructor.component.html',
  styleUrls: ['./constructor.component.less']
})
export class ConstructorComponent implements OnInit {

  kpi = 80;

  product;
  config;
  configuration = false;

  defaultPrice = 0;
  additionalPrice = 9300;

  totalPrice = 0;

  priceButtons = [{
    value: 500,
    activated: false
  }, {
    value: -1200,
    activated: false
  }];

  constructor(
    private productsService: ProductsService,
    private cartService: CartService,
    private configService: ConfigService,
    private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.product = this.productsService.getById(this.route.snapshot.paramMap.get('id'));
    this.config = this.configService.getConfig(this.product.id);
    this.defaultPrice = this.product.price;
    this.recountPrice();
    this.totalPrice = this.additionalPrice + this.defaultPrice;
  }

  save() {
    // alert('Сохранено');
    // this.searchService.setNewPrice(this.route.snapshot.paramMap.get('id'), this.defaultPrice);
    this.router.navigate(['/product/' + this.route.snapshot.paramMap.get('id')]);
  }

  toggleCost(btn) {
    if (btn) {
      btn.activated = !btn.activated;
      this.totalPrice += btn.activated ? btn.value : -btn.value;
    }
  }

  recountPrice() {
    this.kpi = 80;
    this.additionalPrice = 10800;
    for (let key in this.config) {
      if (key === 'id' || key === 'selection') {
        continue;
      }
      this.config[key].forEach(item => {

        if (item.hasOwnProperty('value')) {
          if (item.value) {
            this.additionalPrice += 300;
          } else {
            this.additionalPrice -= 300;
          }
        }

        if (item.hasOwnProperty('dataList')) {

          item.dataList.forEach(dataItem => {
            if (dataItem.hasOwnProperty('value')) {
              if (dataItem.value) {
                this.additionalPrice += 300;
              } else {
                this.additionalPrice -= 300;
              }
            }

            if (dataItem.hasOwnProperty('params')) {

              dataItem.params.forEach(param => {
                if (param.value) {
                  this.additionalPrice += 300;
                } else {
                  this.additionalPrice -= 300;
                }
              });

            }

          });

        }

      });

    }
    console.log(this.additionalPrice);
    this.kpi = this.kpi + Math.round(this.additionalPrice / 3000);

    if (this.kpi > 80 && this.kpi < 85) { this.kpi = 85; }
    if (this.kpi > 85 && this.kpi < 90) { this.kpi = 90; }
    if (this.kpi > 90 && this.kpi < 95) { this.kpi = 95; }
    if (this.kpi > 96 && this.kpi < 97) { this.kpi = 97; }
    if (this.kpi > 97 && this.kpi < 98) { this.kpi = 98; }
    if (this.kpi > 98) { this.kpi = 98; }


    this.totalPrice = this.additionalPrice + this.defaultPrice;
  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    if (value === 1) {
      return 'раз в минуту';
    }
    if (value === 2) {
      return 'раз в час';
    }
    if (value === 3) {
      return 'раз в сутки';
    }

    if (value === 4) {
      return 'раз в неделю';
    }
    if (value === 5) {
      return 'раз в месяц';
    }

    return value;
  }

  formatLabelKPI(value: number | null) {
    if (!value) {
      return 0;
    }

    return value + '%';
  }

}
