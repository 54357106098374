<div class="short-order">
  <h3>Состав заказа:</h3>
  <div class="small">{{orders.length}} сервис{{(orders.length===1)?'':(orders.length===2 || orders.length===3 || orders.length===4)?'а':'ов'}}</div>
  <div class="order-list">
    <div class="order-item" *ngFor="let order of orders; index as i">
      <div>{{order.name}}</div>
      <div>{{order.price.toLocaleString()}} <span class="rubble">₽</span></div>
    </div>
  </div>
  <div class="content-bottom">
    <div><span>Итоговая стоимость:</span> <span>{{getPrice().toLocaleString()}} ₽</span></div>
    <button [routerLink]="['/payment']">Выставить счет</button>
  </div>
</div>
