<div class="filter-block">
  <div class="search-block">

    <div class="filters">
      <input placeholder="Поиск" [(ngModel)]="searchFilter.title" class="search" (ngModelChange)="search()">
      <mat-form-field>
        <mat-select placeholder="Отрасль" [(value)]="searchFilter.department" (valueChange)="search()">
          <mat-option>-- не выбрано --</mat-option>
          <mat-option *ngFor="let department of departments" [value]="department.value" >
            {{department.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="Регион" [(value)]="searchFilter.region" (valueChange)="search()">
          <mat-option>-- не выбрано --</mat-option>
          <mat-option *ngFor="let region of regions" [value]="region.value">
            {{region.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
