import { Component, OnInit } from '@angular/core';
import { CartService } from '@/_services';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.less']
})
export class CartComponent implements OnInit {

  cart = [];

  constructor(
    private cartService: CartService
  ) { }

  ngOnInit() {
    this.cart = this.cartService.getCart();
  }

  removeProductFromCart(id) {
    this.cartService.removeProduct(id);
    this.cart = this.cartService.getCart();
  }

  getPrice() {
    return this.cart.reduce((total, product) => total + product.price, 0);
  }

}
