/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./short-cart.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./short-cart.component";
import * as i4 from "../../_services/cart.service";
var styles_ShortCartComponent = [i0.styles];
var RenderType_ShortCartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShortCartComponent, data: {} });
export { RenderType_ShortCartComponent as RenderType_ShortCartComponent };
export function View_ShortCartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "cart-block"], ["title", "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u0432 \u043A\u0430\u0440\u0437\u0438\u043D\u0443"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "amount"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["x", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "price"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " p."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041E\u0444\u043E\u0440\u043C\u0438\u0442\u044C"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, "/cart"); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.cart.length; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.getPrice().toLocaleString(); _ck(_v, 6, 0, currVal_2); }); }
export function View_ShortCartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-short-cart", [], null, null, null, View_ShortCartComponent_0, RenderType_ShortCartComponent)), i1.ɵdid(1, 114688, null, 0, i3.ShortCartComponent, [i4.CartService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShortCartComponentNgFactory = i1.ɵccf("app-short-cart", i3.ShortCartComponent, View_ShortCartComponent_Host_0, {}, {}, []);
export { ShortCartComponentNgFactory as ShortCartComponentNgFactory };
